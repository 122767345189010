import React, { useRef, useState, useEffect } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { breakpoints, Grid } from "../styles/global"
import useOutsideClick from "../utils/useOutsideClick"
import { useFilter } from "~context/siteContext"
import { sentenceCase } from "change-case"

const { tablet } = breakpoints

const SearchFilter = styled.div`
    ul {
        list-style-type: none;
        position: absolute;
        margin-top: -28px;
        padding-top: 36px;
        padding-bottom: 1rem;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 1) 27.14%, rgba(255, 255, 255, 0) 100%);
    }
    li {
        margin: 0;
    }
`
const Dropdown = styled.div`
    width: 48px;
    height: 48px;
    font-size: 14px;
`

const StyledInput = styled("input")`
    color: var(--black);
    background: transparent;
    border: none;
    -webkit-appearance: none;
    flex: 1;
    padding: 0px;
    margin-right: 0.5rem;
    font-family: inherit;
    ::placeholder {
        font-size: 14px;
        color: var(--black);
    }
`
const StyledButton = styled("button")`
    padding: 1px 0px;
`


const CustomForm = () => {

    const showButtonRef = useRef()
    const sortButtonRef = useRef()
    const { setSort, sort, setShow, show, setSearchTerm, searchTerm } = useFilter()

    useEffect(() => {
      if(sort){
        sortisActive(false)
      }
      if(show){
        showisActive(false)
      }
    }, [sort, show])

    const typeNames = {
      artist: 'Artists',
      curator: 'Curators',
      event: 'Events',
      article: 'News',
      program: 'Programs',
      textDoc: 'Texts',
      work: 'Works'
    }
    // Reset filters tablet
    const [width, setWidth] = useState(null);
    const [term, setTerm] = useState('');

    useEffect(() => {
      if(!width){
        setWidth(window.innerWidth)
      }
      function handleResize() {
        setWidth(window.innerWidth)
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width])

    useEffect(() => {
      setTerm(searchTerm)
    }, [searchTerm])

    useEffect(() => {
      if(width < 1024){
        setShow(null)
        setHoveredType(null)
      }
    },[width])
    //set show is listed as a dependency here, just so that es-lint doesn't complain about exhaustive-deps, it's only ok to do that, because the value of setShow doesn't change, otherwise it would result in an infinite loop

    // Click outside toggle
    useOutsideClick(showButtonRef, () => {
        showisActive(false)
    });

    useOutsideClick(sortButtonRef, () => {
        sortisActive(false)
    });

    // Show dropdown
    const [showActive, showisActive] = useState(false)

    const showDropdownActive = (event) => {
        if (sortActive) {
            sortisActive(false)
        }
        event.target.classList.toggle("active")
        // setShow(null)
        setHoveredType(null)
        showisActive(!showActive)
    }
    // Sort dropdown
    const [sortActive, sortisActive] = useState(false)

    const sortDropdownActive = (event) => {
        if (showActive) {
            showisActive(false)
        }
        event.target.classList.toggle("active")
        sortisActive(!sortActive)
    }

    // Hovered Type
    const [hoveredType, setHoveredType] = useState(null)

    // Hovered Field
    const [hoveredField, setHoveredField] = useState(null)

    const updateHoveredField = (event) => {
        let fieldHovered = event.target.getAttribute('field')
        setHoveredField(fieldHovered)
    }

    const handleSearchSubmit = e => {
      e.preventDefault()
      setSearchTerm(term)
    }

    return (
      <SearchFilter css={css`${tablet}{
          display: none;
      }`}>
        <form onSubmit={e => handleSearchSubmit(e)} css={css`
            border-bottom: 2px solid #fff;
            width: 100%;
            display: flex;
            padding-bottom: 3px;
            `}>
            <StyledInput onChange={e => setTerm(e.target.value)} value={term ?? ''} onBlur={() => !term?.length ? setSearchTerm(term) : null}/>
            <StyledButton className='h3' type="submit">
                Search
            </StyledButton>
        </form>
        <Grid css={css`grid-template-columns: 1fr 1fr 1fr;`}>
            <div css={css`
                display: flex;
                align-items:flex-start;
                `}>
                <Dropdown ref={showButtonRef} css={css`background: ${showActive ? 'none' : 'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 1) 27.14%, rgba(255, 255, 255, 0) 100%)'};`}>
                    <button id='show' onClick={showDropdownActive} css={css`
                        margin-top: 16px;
                        position: relative;
                        z-index: 2;
                        white-space: nowrap;
                        width: 48px;
                        border-bottom: ${showActive ? '1px solid #000' : 'none'};
                        padding-bottom: ${showActive ? '2px' : '0'};
                    `}>
                        { show || hoveredType ? '': 'Show' }
                        {typeNames[hoveredType] ?? typeNames[show]}
                    </button>
                    { showActive ? <ul>
                        <li className="h3"><button onClick={() => setShow('artist')} onMouseEnter={() => setHoveredType('artist')} onMouseLeave={() => setHoveredType(null)} >Artists</button></li>
                        <li className="h3"><button onClick={() => setShow('curator')} onMouseEnter={() => setHoveredType('curator')} onMouseLeave={() => setHoveredType(null)} >Curators</button></li>
                        <li className="h3"><button onClick={() => setShow('event')} onMouseEnter={() => setHoveredType('event')} onMouseLeave={() => setHoveredType(null)} >Events</button></li>
                        <li className="h3"><button onClick={() => setShow('article')} onMouseEnter={() => setHoveredType('article')} onMouseLeave={() => setHoveredType(null)} >News</button></li>
                        <li className="h3"><button onClick={() => setShow('program')} onMouseEnter={() => setHoveredType('program')} onMouseLeave={() => setHoveredType(null)} >Programs</button></li>
                        <li className="h3"><button onClick={() => setShow('textDoc')} onMouseEnter={() => setHoveredType('textDoc')} onMouseLeave={() => setHoveredType(null)} >Texts</button></li>
                        <li className="h3"><button onClick={() => setShow('work')} onMouseEnter={() => setHoveredType('work')} onMouseLeave={() => setHoveredType(null)} >Works</button></li>
                    </ul> : null}
                </Dropdown>
            </div>
            <div css={css`
                display: flex;
                align-items:flex-start;
                `}>
                <Dropdown ref={sortButtonRef} css={css`background: ${sortActive ? 'none' : 'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 1) 27.14%, rgba(255, 255, 255, 0) 100%)'};`}>
                    <button id='sort' onClick={sortDropdownActive} css={css`
                        margin-top: 16px;
                        position: relative;
                        z-index: 2;
                        white-space: nowrap;
                        width: 48px;
                        border-bottom: ${sortActive ? '1px solid #000' : 'none'};
                        padding-bottom: ${sortActive ? '2px' : '0'};
                    `}>
                        { sort || hoveredField ? 'by ': 'Sort' }
                        {hoveredField ?? (sort ? sentenceCase(sort) : null)}
                    </button>
                    { sortActive ? <ul>
                        <li className="h3"><button onClick={() => setSort('title')} onMouseEnter={updateHoveredField} onMouseLeave={() => setHoveredField(null)} field="Title">Title</button></li>
                        <li className="h3"><button onClick={() => setSort('date')} onMouseEnter={updateHoveredField} onMouseLeave={() => setHoveredField(null)} field="Date">Date</button></li>
                    </ul> : null}
                </Dropdown>
            </div>
        </Grid>
      </SearchFilter>
    );
  };



function Search() {

    return (
        <div>
            <div className="form-wrap" css={css`margin-top: 4px;`}>
                <CustomForm />
            </div>
        </div>
    )
}

export default Search
